const ImageData = [
    {
        id: 1,
        cover: require("../../Assets/Gallery/Untitled-2-1.jpg"),
    },
    {
        id: 2,
        cover: require("../../Assets/Gallery/Untitled-2-3.jpg"),
    },
    {
        id: 3,
        cover: require("../../Assets/Gallery/Untitled-4.jpg"),
    },
    {
        id: 4,
        cover: require("../../Assets/Gallery/Untitled9-2.jpg"),
    },
    {
        id: 5,
        cover: require("../../Assets/Gallery/Untitled000.jpg"),
    },
    {
        id: 6,
        cover: require("../../Assets/Gallery/Untitled12.jpg"),
    },
    {
        id: 7,
        cover: require("../../Assets/Gallery/Untitled13.jpg"),
    },
    {
        id: 8,
        cover: require("../../Assets/Gallery/Untitled14.jpg"),
    },
    {
        id: 9,
        cover: require("../../Assets/Gallery/Untitled15.jpg"),
    },
    {
        id: 10,
        cover: require("../../Assets/Gallery/Untitled17.jpg"),
    },
    {
        id: 11,
        cover: require("../../Assets/Gallery/Untitled18-1.jpg"),
    },
    {
        id: 12,
        cover: require("../../Assets/Gallery/Untitled20.jpg"),
    },
    {
        id: 13,
        cover: require("../../Assets/Gallery/Untitled21.jpg"),
    },
    {
        id: 14,
        cover: require("../../Assets/Gallery/Untitled22.jpg"),
    },
    {
        id: 15,
        cover: require("../../Assets/Gallery/Untitled23.jpg"),
    },
    {
        id: 16,
        cover: require("../../Assets/Gallery/Untitled24.jpg"),
    },
    {
        id: 17,
        cover: require("../../Assets/Gallery/Untitled25.jpg"),
    },
    {
        id: 18,
        cover: require("../../Assets/Gallery/Untitled26.jpg"),
    },
    {
        id: 19,
        cover: require("../../Assets/Gallery/Untitled27.jpg"),
    },
    {
        id: 20,
        cover: require("../../Assets/Gallery/Untitled28.jpg"),
    },
    {
        id: 21,
        cover: require("../../Assets/Gallery/Untitled29.jpg"),
    },
    {
        id: 22,
        cover: require("../../Assets/Gallery/Untitled30.jpg"),
    },
    {
        id: 23,
        cover: require("../../Assets/Gallery/Untitled31.jpg"),
    },
    {
        id: 24,
        cover: require("../../Assets/Gallery/Untitled32.jpg"),
    },
    {
        id: 25,
        cover: require("../../Assets/Gallery/Untitled33.jpg"),
    },
    {
        id: 26,
        cover: require("../../Assets/Gallery/Untitled34.jpg"),
    },
    {
        id: 27,
        cover: require("../../Assets/Gallery/Untitled35.jpg"),
    },
    {
        id: 28,
        cover: require("../../Assets/Gallery/Untitled36.jpg"),
    },
    {
        id: 29,
        cover: require("../../Assets/Gallery/Untitled37.jpg"),
    },
    {
        id: 30,
        cover: require("../../Assets/Gallery/Untitled38.jpg"),
    },
    {
        id: 31,
        cover: require("../../Assets/Gallery/Untitled39.jpg"),
    },
    {
        id: 32,
        cover: require("../../Assets/Gallery/Untitled40.jpg"),
    },
    {
        id: 33,
        cover: require("../../Assets/Gallery/Untitled41-1.jpg"),
    },
    {
        id: 34,
        cover: require("../../Assets/Gallery/Untitled42.jpg"),
    },
    {
        id: 35,
        cover: require("../../Assets/Gallery/Untitled43.jpg"),
    },
    {
        id: 36,
        cover: require("../../Assets/Gallery/Untitled44.jpg"),
    },
    {
        id: 37,
        cover: require("../../Assets/Gallery/Untitled45.jpg"),
    },
    {
        id: 38,
        cover: require("../../Assets/Gallery/Untitled47.jpg"),
    },
    {
        id: 39,
        cover: require("../../Assets/Gallery/Untitled46.jpg"),
    },
    {
        id: 40,
        cover: require("../../Assets/Gallery/Untitled48.jpg"),
    },
    {
        id: 41,
        cover: require("../../Assets/Gallery/Untitled49.jpg"),
    },
    {
        id: 40,
        cover: require("../../Assets/Gallery/Untitled50.jpg"),
    },
    {
        id: 40,
        cover: require("../../Assets/Gallery/Untitled51.jpg"),
    },
    {
        id: 40,
        cover: require("../../Assets/Gallery/Untitled52.jpg"),
    },
    {
        id: 40,
        cover: require("../../Assets/Gallery/Untitled53.jpg"),
    },
    {
        id: 40,
        cover: require("../../Assets/Gallery/Untitled54.jpg"),
    },
    {
        id: 40,
        cover: require("../../Assets/Gallery/Untitled55.jpg"),
    },
    {
        id: 40,
        cover: require("../../Assets/Gallery/Untitled56.jpg"),
    },
    {
        id: 40,
        cover: require("../../Assets/Gallery/Untitled57.jpg"),
    },
    {
        id: 40,
        cover: require("../../Assets/Gallery/Untitled58.jpg"),
    },
    {
        id: 40,
        cover: require("../../Assets/Gallery/Untitled59.jpg"),
    },
    {
        id: 40,
        cover: require("../../Assets/Gallery/Untitled60.jpg"),
    },
    {
        id: 40,
        cover: require("../../Assets/Gallery/Untitled61.jpg"),
    },
    {
        id: 40,
        cover: require("../../Assets/Gallery/Untitled62.jpg"),
    },
  
]

export default ImageData;