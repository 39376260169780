import ContactForm from '../Footer/ContactForm';

const CategoryData = [
    {
        id: 1,
        category: "LUXURY NORTH INDIA",
        title: "CULTURE, COUNTRYSIDE AND CULINARY",
        desc: "Plan your luxury vacation India with Lewis and Clark Tours. Mesmerize your Luxury North India Tour with beautiful Taj Mahal, luxury heritage palaces in colourful spellbinding Rajasthan, overnighting in Oberoi Jungle Lodge and explore India Tiger wildlife Safari. Be witness to the enriched divine and culture in Varanasi. Our Luxury India tour packages are filled with colourful stories and planned with best private tour escort and professional chauffeur driven transports. Please check out our luxury North India Tour packages. The very popular Luxury tour is Golden Triangle India Tour We also offer customized luxury India private packages.   ",
        subCategory: [
            {
                id: 1,
                titleTag: "Luxury Golden Triangle India Tour package | Golden Triangle Luxury Tour",
                descriptions:"Explore the exotic journey with Luxury Golden Triangle Tour in India. Experience the breathtaking sunrise visit to the Taj Mahal. Enthrill with the magical charm of India Golden Triangle Tour Package and discover the enchanting cities Delhi, Agra, and Jaipur together.",
                cover: require('../../Assets/Categories/LUXURY-GOLDEN-TRIANGLE-TOUR.jpg'),
                alt: "LUXURY GOLDEN TRIANGLE TOUR",
                title: "Luxury Golden Triangle Tour",
                days: "8 Nights/9 Days",
                location: "Delhi - Agra - Jaipur - Delhi",
                desc: " Explore India and its luxury. Delhi Agra Jaipur golden Triangle Tour will impress you with its highlights Taj Mahal and with the colourful Jaipur. Luxury Golden triangle tour well planned with English Guide and chauffer driven car.",
                className: "category-body1",
                contactForm: <ContactForm/>,
                pathName: "golden-triangle-india-tours",
                banner: require('../../Assets/Categories/Banner-Golden-Triangle-4-nights-5-days.jpg'),
                bannerTag: "golden triangle india tour",
                overview: require('../../Assets/Categories/GoldenTriangle/GOLDEN-TRIANGLE-INDIA-TOUR_.jpeg'),
                overviewTag: "rajasthan tour packages",
                overviewDetail: [
                    {
                        id: 1,
                        detail: "The customized Golden Triangle tour leads you with splendor and grandeur of luxury India and exotic of north India. Covering the golden triangle cities Delhi, Agra, Jaipur gives you the unforgettable experience. The luxury golden triangle will bring you the royal experience by staying in the luxury hotels of Delhi, Agra and Jaipur. The exotic north India tour begins in Delhi, the capital city of India, known for its glorious history and modern-day hustle-bustle.",
                    },
                    {
                        id: 2,
                        detail: "The Delhi, Agra, Jaipur tour stops at Agra, the home to the magnificent Taj Mahal, the most beautiful one of the Seven Wonders of the World. Finally your luxury India tour concludes in Jaipur, known as the Pink City and gem city. Jaipur destination is famous for its majestic forts, palaces, and vibrant culture and delicious cuisine. The exotic India Tour offers a glimpse of India’s diverse cultural heritage and architectural marvels. The Delhi Golden triangle tour is the perfect match to dream, explore and experience India",
                    }
                ],
                tour: [
                    {
                        id: 1,
                        tourCover: require('../../Assets/Categories/WildLife/Layer-1.jpeg'),
                        alt: "golden triangle tours from delhi",
                        detail1: "Arrival at Delhi airport, you will be greeted and received by our tour manager. Transfer to the hotel and check-in at the hotel. You will be handed over the documents for Delhi Agra Jaipur tour package.",
                        detail2: "The first day of the Jaipur tour package from Delhi starts with the Lodhi Garden, a tranquil oasis in the heart of bustling Delhi, where visitors can escape the chaos of the city and enjoy a peaceful stroll amidst lush greenery. Followed by Gandhi Memorial dedicated to the life and legacy of Mahatma Gandhi, we will drive past the Government building, which symbolizes a vibrant democracy. Visit also Jama mosque and the bustling spice market by enthralling rickshaw ride. Upon finishing, the day tour transport to the hotel.",
                        arrival: "Day 1: Arrival in Delhi",
                    },
                    {
                        id: 2,
                        tourCover: require('../../Assets/Categories/GoldenTriangle/Layer-1-1.png'),
                        alt: "golden triangle tour packages",
                        detail1: "Delhi is known for its cultural delights and is the capital of the world’s largest democracy with the oldest civilization. On second day of the Golden Triangle Delhi Agra Jaipur Tour, we will bring you to India’s rich cultural and historical heritage, Qutub Minar and followed by the most magnificent breathtakingly beautiful Temple of Akshardham Temple.  Eventually we will take you to Sikh Temple, where Sikh holy book Guru Granth Sahib is kept, and known for its a free community kitchen (Langar), regardless of the religion or social status. After Delhi sightseeing, transfer to your luxury hotel Delhi.",
                        arrival: "Day 2: Delhi Sightseeing",
                    },
                    {
                        id: 3,
                        tourCover: require('../../Assets/Categories/GoldenTriangle/Layer-2-4.png'),
                        alt: "golden triangle trip india",
                        detail1: "On day 3 of the Delhi Agra Jaipur Tour, we will showcase another testament to India’s, Mehrauli village a medieval heritage, and one of the oldest and most historic neighborhoods in Delhi dating back to the 11th century. Later drive to Lotus temple, a stunning and unique temple, providing you with a sense of serenity. Upon full day Delhi city tour, transfer the hotel.",
                        arrival: "Day 3: Delhi",
                    },
                    {
                        id: 4,
                        tourCover: require('../../Assets/Categories/GoldenTriangle/Layer-3-4.png'),
                        alt: "tour delhi agra jaipur",
                        detail1: "Post your breakfast, drive to Taj Mahal city Agra. Afternoon free at leisure. For the memorable experience of your India Golden tour package, drive to the Taj Mahal evening theatre show to learn about enriched Mughal history. Later transfer to your luxury hotel Agra.",
                        arrival: "Day 4: Delhi-Agra",
                    },
                    {
                        id: 5,
                        tourCover: require('../../Assets/Categories/GoldenTriangle/Layer-4-3.png'),
                        alt: "taj mahal tour packages",
                        detail1: "Today’s Sunrise Taj visit is the highlight of the Golden triangle India tour package. city tour starts with the sunrise visit to the iconic symbol of love- Taj Mahal. The beauty and splendor of the Taj Mahal are enough to give you the experience of exotic India, an art piece built in 22 years by the emperor for his beloved. After a mesmerizing experience at the Taj Mahal, transfer to the hotel for breakfast.",
                        detail2: "Post breakfast we will head towards Itimad-Ud-Daulah, known as Baby Taj. The intricate carving and inlay work of Itimad-Ud-Daulah gives you the thrilling and breathtaking experience of Luxury India. Next, we will visit another masterpiece of Mughal architecture, Red Fort, a UNESCO world heritage known for its stunning massive Redstone walls. After watching the grandeur and opulence of Red Fort, transfer to the hotel.",
                        arrival: "Day 5: Agra Sightseeing",
                    },
                    {
                        id: 6,
                        tourCover: require('../../Assets/Categories/GoldenTriangle/Layer-5-2.png'),
                        alt: "jaipur rajasthan tour packages",
                        detail1: "Your luxury golden triangle tour of India drives you to the next destination Jaipur. Stop at the rustic gem Abhaneri, a thousand-year-old architectural marvel famous for its Chand Baori step well and authentic delicacies. Drive to the luxury Jaipur hotel.",
                        arrival: "Day 6: Agra-Jaipur",
                    },
                    {
                        id: 7,
                        tourCover: require('../../Assets/Categories/GoldenTriangle/Layer-6-3.png'),
                        alt: "golden triangle tour",
                        detail1: "Welcome to the pink city! Jaipur is the wonderful destination of your Delhi golden triangle tour package. We will explore the gem city, which covers impressive Amber fort, having 14 Km wall, located in Old City. Stop for photography at Jal Mahal. Amber fort beholds Rajput architecture with intricate carving, mirror works and paintings. Visit also monkey temple – Galta ji and Birla Temple. Later on, transfer to the hotel.",
                        arrival: "Day 7:  Jaipur Sightseeing",
                    },
                    {
                        id: 8,
                        tourCover: require('../../Assets/Categories/GoldenTriangle/Layer-7-3.png'),
                        alt: "new delhi agra jaipur tour",
                        detail1: "Enjoy your last day of your Jaipur tour package. Feel the incredible India with vibrant atmosphere in Jaipur. Hawa Mahal, a colourful symbol of rich Indian architectural heritage built for the women’s purpose; feel mesmerized with timeless panoramic views and its royal grandeur",
                        detail2: "Finally, explore Jantar Mantar and City Palace, the most iconic landmarks and a collection of astronomical instruments built in the early 18th century by Maharaja Jai Singh Il. The hospitality and warmth of the people of Rajasthan makes it an unforgettable experience of exotic north India. Drive back to your luxury hotel Jaipur.",
                        arrival: "Day 8: Jaipur",
                    },
                    {
                        id: 9,
                        tourCover: require('../../Assets/Categories/GoldenTriangle/Layer-8-3.png'),
                        alt: "north india holiday packages",
                        detail1: "Check out from the hotel and drive to New Delhi. End of nine day Delhi Agra Jaipur tour package.",
                        detail2: "Our travel company offers different types of Jaipur tour package and plans tailor made luxury India tour individually and authentically for you. Please feel free to contact us for your Golden triangle India tour package price.",
                        arrival: "Day 9: Jaipur - Delhi",
                    },
                ]
            },
            {
                id: 2,
                titleTag: "Rajasthan Luxury Tour Packages | Royal Rajasthan Tour, Taj Hotel Rajasthan Package",
                descriptions:"Rajasthan is known for its royal stories and fairytales in India Luxury Vacation packages. Learn its colorful culture and vibrant traditions in Luxury Rajasthan Tour packages and feel like Maharaja in your luxury tour.",
                cover: require('../../Assets/Categories/Rajasthan-holiday-trip.jpg'),
                alt: "rajasthan travel packages",
                title: "LUXURY INDIA THROUGH THE LAND OF COLORS",
                days: "16 NIGHTS/ 17 DAYS",
                location: "Delhi - Agra - Ranthambore - Jaipur - Shahpura - Jodhpur - Naralai - Udaipur - Delhi",
                desc: "Get to know colourful Rajasthan and its fascinating fairy-tale! Feel Incredible India in real sense during Rajasthan holiday trip. Your luxury private travel to India well planned with mixture of Luxury hotel, visit of historical, cultural sights and countryside Rajasthan like Rawala Narlai and Shahpura to make your Luxury vacation India unforgettable and memorizing.",
                className: "category-body2",
                pathName: "luxury-india-through-the-land-of-colours",
                downTitle: "Rajasthan Luxury Tour Packages | Nature Safari India Tour package | India Family Tour Packages",
                downdetail1: "Mesmerizing journey through the heart of India with our exclusive India Safari Tour, where adventure meets luxury. Explore the majestic landscapes of Rajasthan with our specially crafted Rajasthan Holiday Packages and indulge in opulence with our Rajasthan Luxury Tour Packages. For those seeking romance, our India Honeymoon Tours offer a perfect blend of intimacy and cultural richness. ",
                downdetail2: "Families can create lasting memories with our India Family Tour Packages, ensuring a delightful experience for all. Nature enthusiasts can immerse themselves in the breathtaking wilderness with our Nature Safari India Tour package, while wildlife enthusiasts can witness the majestic tigers with our Tiger Wildlife India Package. ",
                downdetail3: "Experience the epitome of personalized travel with our Private Tours India and elevate your journey with the utmost comfort through our Private Luxury India Tours. Discover the diverse facets of India in style and create memories that will last a lifetime.",
                banner: require('../../Assets/Categories/LUXURY-INDIA-THROUGH-THE-LAND-OF-COLOURS-_-banner.jpg'),
                bannerTag: "best holiday packages in india",
                overview: require('../../Assets/Categories/Rajasthan/New-Bitmap-Image.jpg'),
                overviewTag: "trips to india from usa",
                contactForm: <ContactForm/>,
                overviewDetail: [
                    {
                        id: 1,
                        detail: "We are Luxury tour operators in India and offer best tours to India from USA. We provide best Tour Packages in India. Lewis & Clark Tour and Travels plan you India tours, exclusively as best escorted tours of India with best luxury hotels in India. Plan your luxury Tours to India from USA with us.",
                    },
                    {
                        id: 2,
                        detail: "Plan your Luxurious holiday through the land of colors!  Experience beautiful facets of India with magnificent heritage hotels, Exotic India textiles &women’s art!  This Taj hotel Rajasthan package leads you once across North India. Welcome to the best escorted tours of India! This Luxury India tour package starts in the 9 million metropolises and Incredible India’s capital New Delhi. You will admire the cultural monuments of this India tours. Your India private tour package goes to Agra, explore here the world famous “Taj Mahal” and experience the beauty of Taj Mahal at sunrise! From Taj city, then further the Tours to Rajasthan. Discover Indian wildlife Tiger Safari in Ranthambhore and overnighting at luxury Lodge of Taj and Oberoi Group and capturing Indian nature closely.  Head your Luxury Rajasthan Tour packages to Jaipur, also known the Gem City of maharaja and the capital of fearless Rajput states.  Relax at best Luxury India hotels, like Taj Hotels, Oberoi Hotels, Rambagh palace, known Jaipur Luxury Properties.",
                    },
                    {
                        id: 3,
                        detail: "Feel thrilled with colourful Rajasthan as Jaipur called “pink city.” Amber Fort, Maharaja exquisite places. Experience the fairy-tale life of the Maharajas at Amber Fort. In contrast, feel amazed in centuries-old villages and enjoy exclusively planned village safari. Learn the Rajasthani culture in Narlai, determined by agriculture, trade and camel and goat breeding. Meet cheerful people and have the opportunity to stay and interact with local families and get to know about traditional music and dances over dinner. Explore impressive fortress of Blue City Jodhpur, the gateway to Thar Desert and further the “Venice of East” Udaipur, also known as White City. The artful Udaipur is located on Lake Pichola, and has often known as “East of Venice.”",
                    },
                ],
                tour: [
                    {
                        id: 1,
                        tourCover: require('../../Assets/Categories/Rajasthan/Luxury-India-through1.jpg'),
                        alt: "holiday packages from delhi",
                        detail1: "India tours from Delhi starts. Upon Arrival at the airport, pick up and transfer to hotel. Delhi welcomes you with its warmth.  Afternoon visit to the world’s largest temple Akshardham Temple, a Hindu place of worship, amalgamation of religion and Hindu culture. Transfer to your hotel. Overnight stay in Delhi.",
                        arrival: "Day 01: Delhi ",
                    },
                    {
                        id: 2,
                        tourCover: require('../../Assets/Categories/Rajasthan/Jama-Masjid2.jpg'),
                        alt: "india trip package",
                        detail1: "Explore Delhi all day with our best English speaking tour guide Escort. Post breakfast Delhi city tour begins. Visit Gandhi Memorial in Old Delhi, stop for a photo stop at the Red Fort and visit the Friday Mosque Jama Masjid before taking a rickshaw ride through the winding streets Chandani Chowck. Asia’s largest spice market a must be in old city. In New Delhi, continue to India Gate and the magnificent buildings of the Parliament Quarter and the Qutub Minar.",
                        detail2: "In the evening, be prepared to learn the secrets of Indian cuisine, an exciting cooking demonstration and dine with a local Indian family. Overnight stay in Delhi",
                        arrival: "Day 02: Delhi",
                    },
                    {
                        id: 3,
                        tourCover: require('../../Assets/Categories/Rajasthan/Rajasthan-Tour3.jpg'),
                        alt: "agra taj mahal tour packages",
                        detail1: "Today the Rajasthan Tour Packages continues to Agra. Arrival in Agra in afternoon. Because of the Taj Mahal, Agra is known worldwide and is one of the most famous destinations in India. Afternoon at leisure. Enjoy the local bazaar. Overnight stay in Agra. ",
                        arrival: "Day 03: Delhi - Agra (230 km / 4 hours)",
                    },
                    {
                        id: 4,
                        tourCover: require('../../Assets/Categories/Rajasthan/Agra-Ranthambore4.jpg'),
                        alt: "north India tour",
                        detail1: "Agra is excellent for today’s Taj Mahal sightseeing program. At sunrise, enjoy Taj Mahal built by Mughal Shah Jahan in honor of his wife Mumtaz Mahal. Today, the Taj Mahal is probably the most beautiful and impressive sight of love in the world and represents a very special highlight in north India tour. After visiting the Taj Mahal transfer to the hotel. Afternoon visit to Taj City Agra. Here you will visit the Agra Fort. The “Red Fort” is a fort and palace complex in Agra. It was built in the era of Mughal emperors and served as their resistance. The fort with inner palace complexes tells the fairy tale story of India. Diwan-i-Aam, Khaas Mahal, Grape Garden, Jahangir Palace, Jasmine Tower are some beautiful sightseeing points in Agra Fort. A true paradise hides behind the massive fort wall. Overnight stay in Agra. (F) Amar Villas Agra offers the best Taj Mahal view from balcony.",
                        arrival: "Day 04:  Agra (The highlight of Luxury India tour packages).",
                    },
                    {
                        id: 5,
                        tourCover: require('../../Assets/Categories/Rajasthan/Ranthambore5.jpg'),
                        alt: "vacation packages in india",
                        detail1: "After breakfast drive towards Ranthambhore. Ranthambhore, a popular travel destination in Rajasthan, has been known for wildlife sighting. Enjoy the full board service and exquisite services at taj Lodge and Oberoi exclusive lodge located near by nature. Ranthambhore wildlife is a home for Tigers, leopards and other exotic animals. Overnight in Taj / Oberoi Tent house, knows as best jungle resorts and lodge in Rajasthan. Afternoon arrival at Ranthambore. Overnight stay in Ranthambore.",
                        arrival: "Day 05: Agra - Ranthambore (230 km/ 4 hours)",
                    },
                    {
                        id: 6,
                        tourCover: require('../../Assets/Categories/Rajasthan/Ranthambore-Jaipur6.jpg'),
                        alt: "Tiger safari in ranthambore",
                        detail1: "Ranthambore is the former hunting ground of the Maharaja of Jaipur and world famous for its tigers. The landscape is dominated by the ruins of a 10th century fort (UNESCO World Heritage Site). In the morning, you will set out on your first Tiger safari by jeep with Nature expert. In the morning and in the afternoon we will go on two jeep safaris to sight animals in the Ranthambore National Park, The safaris are planned exclusively for you. Overnight stay in hotel.",
                        arrival: "Day 06: Ranthambore",
                    },
                    {
                        id: 7,
                        tourCover: require('../../Assets/Categories/Rajasthan/Jaipur7.jpg'),
                        alt: "india tour packages from usa",
                        detail1: "After breakfast drive to Jaipur. Welcome to this Gem city of Rajasthan. The very fact that camels and elephants participate even in busy traffic here, besides the countless tuk-tuk makes India credible. The whole city looks in pink colour, the reason it is also called Pink City. Transfer to the hotel.",
                        detail2: "*Enjoy the fire prayer and evening ceremony at Birla Temple.    Overnight stay in Jaipur",
                        arrival: "Day 07: Ranthambore - Jaipur (170 km/ 3 hours)",
                    },
                    {
                        id: 8,
                        tourCover: require('../../Assets/Categories/Rajasthan/Jaipur-8.jpg'),
                        alt: "Rajasthan Luxury Tour Package",
                        detail1: "Today have the city tour of Pink City which beholds rich history of Maharaja. An excursion planned the palace fortress of Amber, the former royal palace of the Kachchawaha Dynasty, also cover the fabulous Hall of Mirrors. On the way photo stop at the water palace (Jal Mahal). Also visit the magnificent Maharaja City Palace, former royal residence in Jaipur. The exotic place is divided into a series of courtyards, buildings and gardens, including the Chandra Mahal and the Mubarak Mahal.",
                        detail2: "***A hi tea will be arranged exclusively in Maharaja Private palace. (A highlight of Rajasthan Luxury Tour Package)",
                        detail3: "After a day of adventure, you will be transferred back to the hotel. Spend the night in Jaipur.",
                        arrival: "Day 08:  Jaipur",
                    },
                    {
                        id: 9,
                        tourCover: require('../../Assets/Categories/Rajasthan/Jaipur-Shahpura9.jpg'),
                        alt: "rajasthan holiday packages",
                        detail1: "After breakfast, drive to the monkey temple of Galta Ji, an ancient Hindu pilgrimage site about 10 km from Jaipur. The site consists of a series of temples built into a narrow cleft in the ring of Aravalli hills that surround Jaipur. Afternoon a planned excursion to Sanganer, located in outskirt of Jaipur and famous for the art of block printing. Sanganer is an important place for textile hand printing and handicrafts. Enjoy the beautiful double block printing session. We have also Blue Pottery session.",
                        detail2: "Return to hotel after sightseeing; overnight at hotel.",
                        arrival: "Day 09:  Jaipur",
                    },
                    {
                        id: 10,
                        tourCover: require('../../Assets/Categories/Rajasthan/Shahpura-Jodhpur10.jpg'),
                        alt: "best golden triangle tour packages",
                        detail1: "After breakfast we drive to Shahpura Bagh. Upon arrival, we check in at the hotel. The evening is at leisure. Relax at the hotel! Overnight stay at the hotel. You can explore the charming and rural village nearby. Overnight at the hotel.",
                        arrival: "Day 10: Jaipur - Shahpura( 220 km/ 4 hours)",
                    },
                    {
                        id: 11,
                        tourCover: require('../../Assets/Categories/Rajasthan/umaid-bhawan11.jpg'),
                        alt: "Rajasthan holiday trip",
                        detail1: "Rajasthan holiday trip continues towards Jodhpur, the blue city and the real “gateway to the Thar Desert”. Arrival in Jodhpur in the afternoon. Transfer to hotel",
                        arrival: "Day 11: Shahpura– Jodhpur (240 km/ 4.30 hours)",
                    },
                    {
                        id: 12,
                        tourCover: require('../../Assets/Categories/Rajasthan/Jodhpur12.jpg'),
                        alt: "new delhi agra jaipur tour",
                        detail1: "Full day Sightseeing in Jodhpur. With red sandstone palaces within the fort walls, the Fort complex is one of Rajasthan’s most impressive structures. You will get to see the Umaid Bhawan Palace on Chittar Hill. Construction of the 347-room sandstone and marble palace began in 1929 and was completed 14 years later. In the evening you will have time to visit an old spice market. Overnight in hotel Taj Ummaid Bhavan.",
                        arrival: "Day 12: Jodhpur",
                    },
                    {
                        id: 13,
                        tourCover: require('../../Assets/Categories/Rajasthan/Jodhpur-Narlai13.jpg'),
                        alt: "travel packages from delhi",
                        detail1: "After breakfast you will depart from Jodhpur. You will drive to Narlai. Narlai is a settled in the foothills of the Aravali Mountains in the Pali district of Rajasthan. This day is at your leisure. You can relax or explore the surrounding area. Overnight stay in Narlai. Dinner nearby stepwell is very much recommendable.",
                        arrival: "Day 13: Jodhpur - Narlai (130 km /2 hours)",
                    },
                    {
                        id: 14,
                        tourCover: require('../../Assets/Categories/Rajasthan/Narlai-Udaipur14.jpg'),
                        alt: "north india trip packages",
                        detail1: "Today you will drive to Udaipur, the picturesque city. Around afternoon, you will reach Udaipur and be transferred to your hotel. Udaipur is considered the most romantic city of Rajasthan. With its hill fort, squares like something out of a fairy tale and its urban and modern centre. Overnight at Taj or Oberoi Hotel",
                        arrival: "Day 14: Narlai - Udaipur (120 km/ 2 hours)",
                    },
                    {
                        id: 15,
                        tourCover: require('../../Assets/Categories/Rajasthan/Udaipur15.jpg'),
                        alt: "luxury travel india",
                        detail1: "Udaipur is rightly centred for performing arts, painting and handicrafts. ",
                        detail2: "In the morning, take a leisurely boat ride on Lake Pichola (depending on water levels). After that, your city tour commences. Explore the City Palace, the largest palace complex in India, consisting of a total of eleven buildings. Many of the old marble buildings and fortifications now serve as posh hotels. During your city tour of Udaipur, you will visit Sahelyon Ki Bari, then the royal family temple. After the city tour drive back to the hotel. Overnight stay in Udaipur.",
                        arrival: "Day 15: Udaipur",
                    },
                    {
                        id: 16,
                        tourCover: require('../../Assets/Categories/Rajasthan/Udaipur-Delhi16.jpg'),
                        alt: "tour packages from delhi",
                        detail1: "Transfer to the airport and by flight to Delhi. Transfer to hotel upon arrival in Delhi. Evening is at leisure. Overnight stay in hotel.",
                        arrival: "Day 16: Udaipur - Delhi (By flight)",
                    },
                    {
                        id: 17,
                        tourCover: require('../../Assets/Categories/Rajasthan/Delhi-onward-journey17.jpg'),
                        alt: "Rajasthan Customized tour packages",
                        detail1: "Your seventeen day Rajasthan Luxury Tour Package comes to an end. Morning transfer to Delhi International Airport.",
                        detail2: "Please contact us for your India tours packages prices or Rajasthan tour packages all inclusive or Taj hotel Rajasthan package. If you wish Customized tour packages for Rajasthan with Rajasthan luxury hotels, we make your dream tour to India true. ",
                        arrival: "Day 17: Delhi –onward journey",
                    },
                ]
            },
            {
                id: 3,
                titleTag: "Varanasi Luxury Packages, Golden Triangle Tour with Varanasi, Best Varanasi Packages",
                descriptions:"Learn the mystic of wold's oldest city Varanasi and explore the hidden treasure of north India in Luxury Varanasi Tour tour. Enjoy Privately-Guided Varanasi Tour with Luxury Hotels and make your India Luxury vacation memorable with our Tour packages to Varanasi from USA.",
                cover: require('../../Assets/Categories/LUXURY-GOLDEN-TRIANGLE-VARANASI-TOUR.jpg'),
                alt: "golden triangle india tour packages",
                title: "golden triangle india tour packages",
                days: "10 NIGHTS / 11 DAYS​",
                location: "Delhi - Udaipur - Jaipur - Agra – Orchha-Khajuraho - Varanasi - Delhi",
                desc: "Experience India and its vibrant, attractive culture in Varanasi. Extended Golden triangle India tour will amaze you with luxurious stay, best cultural experience and guided tour. Golden triangle tour with Varanasi is well planned with English guide and an Indian cooking class. ",
                className: "category-body1",
                contactForm: <ContactForm/>,
                pathName: "luxury-north-india-golden-triangle-with-varanasi",
                banner: require('../../Assets/Categories/LUXURY-GOLDEN-TRIANGLE-VARANASI-TOUR_.jpeg'),
                bannerTag: "luxury golden triangle varanasi tour",
                overview: require('../../Assets/Categories/Varanasi/GOLDEN-TRIANGLE-overview.jpeg'),
                overviewTag: "luxury golden triangle tour india",
                overviewDetail: [
                    {
                        id: 1,
                        detail: "Come and feel the magnificence and exuberance of Incredible India with the Luxury Golden Triangle Tour India, popular 10-nights, 11 days exotic tour journey covering some of India’s most historic and culturally significant destinations. The exotic north India tour begins in Delhi, the bustling capital city, moves on to the romantic city of Udaipur, then Pink City of Jaipur, the ancient village of Abhaneri, and the iconic Taj Mahal in Agra.",
                    },
                    {
                        id: 2,
                        detail: "The tour also takes you to the quaint town of Orcha, known for its medieval architecture and temples, the UNESCO World Heritage site of Khajuraho with its famous erotic sculptures, and the spiritual city of Varanasi, situated on the banks of the holy Ganges River. Explore ancient temples, palaces, forts, local markets, taste traditional cuisine, and interact with locals to gain a deeper understanding of Indian customs and ways of life.",
                    }
                ],
                tour: [
                    {
                        id: 1,
                        tourCover: require('../../Assets/Categories/Varanasi/1delhi-tour.jpeg'),
                        alt: "Best Luxury tour operator in Delhi",
                        detail1: "Arrival at Delhi Airport, Tour representative will receive you at arrival. Transfer to the hotel. Check in at the hotel. You will receive documents for your Varanasi tour package. ",
                        detail2: "The first day of the Jaipur tour package from Delhi begins with the Akshardham Temple, an architectural marvel that boasts intricate carvings, beautiful gardens, and stunning water fountains—followed by the 17th-century Jama Masjid, an iconic landmark in Old Delhi, known for its stunning Mughal architecture and rich history. Enjoy the ride with rickshaw, going through the colorful Wedding Street and beauty of Chandani Chowk. Explore the vibrant and bustling spice market, enough to give you the experience of aromas and authentic flavors and the culinary delights of India. Later, transfer to your luxury hotel Delhi.",
                        arrival: "Day 1: Arrival in Delhi",
                    },
                    {
                        id: 2,
                        tourCover: require('../../Assets/Categories/Varanasi/2Layer-13.jpg'),
                        alt: "Luxury Holidays Vacations in India",
                        detail1: "From the winding lanes of Old Delhi to the towering skyscrapers of the modern city, Delhi is a fascinating blend of old and new, tradition and innovation. On Second day of the Oberoi golden triangle tour, we will visit Sikh Temple and participate in the service kitchen also known as langar. This communal meal is served to everyone who visits the temple, regardless of religion or background. Followed by architecture marvel Qutub Minar, a 13th century Delhi Sultanate monument, minaret made of red sandstone and marble and is adorned with intricate carvings and verses from the Quran. Transfer to the Hotel.",
                        arrival: "Day 2: Delhi Sightseeing",
                    },
                    {
                        id: 3,
                        tourCover: require('../../Assets/Categories/Varanasi/3Layer-3-2.png'),
                        alt: "Luxury India private tour",
                        detail1: "Post breakfast transfer to Delhi Airport. Fly to Udaipur. Arrival at Udaipur airport and transfer to hotel for your luxury stay in Udaipur. Udaipur is better known as city of love and “East of Venice.” Afternoon free at your leisure! Udaipur is very beautiful destination of your exotic journey.",
                        arrival: "Day 3: Delhi - Udaipur",
                    },
                    {
                        id: 4,
                        tourCover: require('../../Assets/Categories/Varanasi/4Layer-4-1.jpg'),
                        alt: "Luxury Tour Packages in India",
                        detail1: "On day three of the Jaipur Udaipur Tour package, we will visit glorious Jagdish temple, a perfect blend of mystery, story, and history. Later we will visit City Palace, with a striking style, built atop a hill in a fusion of Rajasthan Rajput architecture, providing a panoramic view of the city and its surroundings. Enjoy the evening boat ride, in the city of lakes Udaipur and experience the beauty and charm of Udaipur. Followed with SаhеliyоnkiВаri (Gardenоfrоyаlladies), Udaipur City tour will end. Transfer to the hotel.",
                        arrival: "Day 4: Udaipur Sightseeing",
                    },
                    {
                        id: 5,
                        tourCover: require('../../Assets/Categories/Varanasi/5Layer-5.jpg'),
                        alt: "Luxury Rajasthan Tour",
                        detail1: "Post Breakfast, we will drive to Jaipur. Enjoy the view of country side view during the drive. Rajasthan is famous for its hospitality. Incredible India believes in Atithi Devo Bhava” meaning “Guest is God” Feel the warmth of local Rajasthan hospitality. Arrival in Jaipur and transfer to the hotel. Overnight at your luxury hotel in Jaipur.",
                        arrival: "Day 5: Udaipur -Jaipur",
                    },
                    {
                        id: 6,
                        tourCover: require('../../Assets/Categories/Varanasi/6Layer-6-1.jpg'),
                        alt: "Luxury India Tour with Oberoi Hotels",
                        detail1: "Your Jaipur Tour guides you to the Pink City, the Gem city. On the 6th day of the Jaipur tour places, we will visit Amer Palace Fort, located at a distance of about 11 km, an extensive palace complex built with pale yellow and pink sandstone and with white marble and followed by Jantar Mantar, UNESCO world heritage, an 18th-century astronomical observatory. It ends with the Hi-Tea at Royal Palace to give you the feel of Luxury Rajasthan. Transfer to the hotel.",
                        arrival: "Day 6:  Jaipur",
                    },
                    {
                        id: 7,
                        tourCover: require('../../Assets/Categories/Varanasi/7Layer-8-1.jpg'),
                        alt: "India Holiday Packages",
                        detail1: "Next, we will go to the city of the Taj Mahal. On day 6 of the Taj Mahal tour package, we will visit the monument of love, the Taj Mahal. The timeless masterpiece of Mughal Architecture Taj Mahal, you will be struck by its sheer size and grandeur. Then we will pass another UNESCO world heritage Red Fort, known for its red marble specialty. Transfer to your luxury hotel.",
                        arrival: "Day 7: Jaipur - Agra",
                    },
                    {
                        id: 8,
                        tourCover: require('../../Assets/Categories/Varanasi/8Khajuraho-Varanasi.jpeg'),
                        alt: "India Private Holiday Package",
                        detail1: "After your breakfast, transfer to railways station. Enjoy the train journey of India from Agra to Jhansi. Arrive at Jhanshi Railway station and drive to the magnificent Orcha, showcasing the excellent Bundela-era primary historical site of the Bundela dynasty. After 2 hours journey, reach Khajuraho, a home of another UNESCO world heritage, Khajuraho is known for their erotic sculptures.",
                        arrival: "Day 8: Agra - Orchha - Khajuraho",
                    },
                    {
                        id: 9,
                        tourCover: require('../../Assets/Categories/Varanasi/9Layer-9-2.jpg'),
                        alt: "India Family Tour Packages",
                        detail1: "Fly to the abode of Lord Shiva and Parvati, Varanasi, also known as Banaras or Kashi, the next stop of your Varanasi tour package. It is a 14,000 years old city, situated at the Bank of river holy Hindu river Ganga. Then be part of Ganga Aarti, carried out by a priestly congregation on the ghats, during the honking of conch shells, the jingling of bells, the crashing of cymbals, and reciting mantras in unison. Transport you to the hotel.",
                        arrival: "Day 9:  Khajuraho - Varanasi",
                    },
                    {
                        id: 10,
                        tourCover: require('../../Assets/Categories/Varanasi/10Layer-11-1.jpg'),
                        alt: "Customized Holiday Packages in India",
                        detail1: "After breakfast, on day 10 of the Varanasi tour guide, we will go for the sunrise boat ride, with the first rays of sunlight, and the painted sky, with boatmen of Varanasi walking to their rowboats at the banks of the River Ganges, where you can see the beauty and panorama of Assighat, and have a tranquil journey in holy river Ganges. It is followed by a visit to the striking temples of Varanasi, whose magnanimity and grandiose attract tourists from worldwide. Transport you to the golden triangle luxury resort.",
                        arrival: "Day 10: Varanasi Sightseeing Tour",
                    },
                    {
                        id: 11,
                        tourCover: require('../../Assets/Categories/Varanasi/12Layer-12-1.jpg'),
                        alt: "India vacation Packages all inclusive",
                        detail1: "Check out the hotel and then fly to New Delhi—end of 11-day Varanasi Tour and Travels.",
                        detail2: "We plan your tailor made India tour and you can opt this luxury north India tour with additional nights for Exotic south India Kerala Ayurveda experience as well for your personal tour stay and for the Yoga Retreat at the beach in Goa.",
                        arrival: "Day 11: Varanasi - Delhi",
                    },

                ]
            },
            {
                id: 4,
                titleTag: "Golden Triangle with Ranthambore, Ranthambore Luxury Safari, Luxury Tiger Safari India",
                descriptions:"Indulge in an ultimate India safari with tiger sighting and enjoy your best luxury golden triangle wildlife tour. Make your Ranthambore and Taj Mahal tour mesmerizing with luxury tour operators India.",
                cover: require('../../Assets/Categories/WILDLIFE-TOUR.jpg'),
                alt: "luxury golden triangle wildlife tour",
                title: "LUXURY GOLDEN TRIANGLE WILDLIFE TOUR",
                days: "10 NIGHTS / 11 DAYS​​",
                location: "Delhi - Agra - Ranthambore - Jaipur - Delhi",
                desc: "Discover Indian Tiger and safari exclusively along with luxury Golden triangle Tour India. Overnighting at Taj Lodge in Ranthambhore, watching Indian nature closely will exciting and highlight of the tour. Feel thrilled with Spice Market visit, Sunrise Taj Mahal, Tiger safaris in Ranthambhore.",
                className: "category-body2",
                contactForm: <ContactForm/>,
                downTitle: "Golden Triangle Luxury Tours | Golden Triangle Tiger Wildlife",
                downdetail1: "Start on a unforgettable journey through the heart of India with Golden Triangle Luxury Tours. Immerse yourself in the rich cultural tapestry of the country as you explore iconic destinations like Delhi, Agra, and Jaipur on the Golden Triangle India Tour. This curated experience blends opulence and heritage, offering travelers a taste of the regal past of these vibrant cities. ",
                downdetail2: "Marvel at the architectural splendor of the Taj Mahal, witness the bustling markets of Delhi, and admire the pink-hued allure of Jaipur’s palaces. For those seeking a thrilling wildlife encounter, the Golden Triangle Tiger Wildlife tour adds an extra layer of excitement, allowing you to discover the majestic creatures that roam the enchanting landscapes surrounding these historical gems. Indulge in the perfect fusion of luxury, culture, and wildlife adventure with the Golden Triangle Tour.",
                pathName: "luxury-golden-triangle-wildlife",
                banner: require('../../Assets/Categories/LUXURY-GOLDEN-TRIANGLE-WILDLIFE.jpeg'),
                bannerTag: "Golden Triangle Tiger Wildlife",
                overview: require('../../Assets/Categories/WildLife/GOLDEN-TRIANGLE-WILDLIFE-overview.jpeg'),
                overviewTag: "Tiger Wildlife India Package",
                overviewDetail: [
                    {
                        id: 1,
                        detail: "Uncover the magic of Exotic India with the Delhi Golden Tour Package, an 11 day trip covering some of India’s celebrated destinations. Begin your journey in Delhi, “The City of Djinns”, and explore the mystical and enchanting atmosphere of the city. Then gaze at the grandeur and opulence of the majestic Taj Mahal before continuing venture into the wilds of Ranthambore and go on a thrilling safari to see tigers, leopards, and other exotic animals. Finally, visit Jaipur’s splendid forts, and palaces entice visitors with their endless tales and legends.",
                    },
                ],
                tour: [
                    {
                        id: 1,
                        tourCover: require('../../Assets/Categories/WildLife/Layer-1.jpeg'),
                        alt: "India Holiday Destinations",
                        detail1: "Your India trip for Golden Triangle with Ranthambore tour will commence in Delhi. Your tour Delhi Agra Jaipur tour package operator receives you. Transfer to a hotel for your luxury stay.",
                        detail2: "The City of Delhi, with a history of over 5000 years has been the center of power, is the perfect place to begin your Agra Jaipur tour package. Your Agra Jaipur tour package starts Sikh Temple, an original facet of Sikhism that emphasizes the importance of community service and Sharing. Moving to the Mughal masterpiece Humayun Tomb, a UNESCO world heritage located in the heart of Delhi, which mesmerizes you with its intricate carvings and geometric patterns. Next, learn more about Mughal architecture by visiting the 17th-century Mughal old Jama Mosque. Discover the aromas and fragrances of Indian spices in the spice market of Delhi. Drive past the UNESCO world heritage site Red Fort, a monument of rich cultural and historical significance. Overnight stay at the hotel.",
                        arrival: "Day 1: Arrival in Delhi",
                    },
                    {
                        id: 2,
                        tourCover: require('../../Assets/Categories/WildLife/Layer-2.png'),
                        alt: "Luxury Holidays Vacations in India",
                        detail1: "Explore the ‘Dilwalo ki Delhi’ ( people welcome by heart) on day 2 of the Delhi Golden triangle tour package. Admire an architectural marvel, Qutub Minar, standing tall and proud amidst the bustling city of Delhi, known for its specialty of red sandstone and its intricate carvings and inscriptions is enough to dazzle you with its elegance. Visit the radiant Lotus temple, exalting you with its serenity. Transfer to the hotel.",
                        arrival: "Day 2: Delhi Sightseeing",
                    },
                    {
                        id: 3,
                        tourCover: require('../../Assets/Categories/WildLife/Layer-3.png'),
                        alt: "Tailor Made India Tours",
                        detail1: "Start your day tour with the iconic government building Lutyens Delhi. Surrounded by sprawling green gardens and exquisite structures.” proceed to Gandhi Smriti, a museum that commemorates the life and teachings of Mahatma Gandhi. Afternoon free at leisure you can enjoy local shopping in Delhi, jewelry to cloth, you can get everything here. Transfer to a hotel for your luxury stay.",
                        arrival: "Day 3: Delhi Sightseeing",
                    },
                    {
                        id: 4,
                        tourCover: require('../../Assets/Categories/WildLife/Layer-4.png'),
                        alt: "Best North India Tour Packages",
                        detail1: "Your luxury golden triangle tour drives you Agra today. Afternoon free at leisure. In the evening visit Kalakriti Theater for a mesmerizing Delhi Agra Jaipur tour.” Transfer you to your Hotel.",
                        arrival: "Day 4: Delhi - Agra",
                    },
                    {
                        id: 5,
                        tourCover: require('../../Assets/Categories/WildLife/Layer-5.png'),
                        alt: "Luxury India Tour from Canada",
                        detail1: "Rise and shine to witness the breathtaking beauty of the Taj Mahal- the ultimate testament to love. As the first light of dawn illuminates the white marble mausoleum, your Agra tour guide will escort you to this unforgettable UNESCO World Heritage site. Taj Mahal is a master art piece finished in 22 years and completed by 20000 craftsmen. After the Taj Mahal visit, transfer the hotel for breakfast. Our Luxury Agra tour continues as we visit the magnificent Red Fort, a stunning example of rich Indian architecture and a UNESCO World Heritage Site renowned for its red marble specialty. Transfer you to your luxury hotel.",
                        arrival: "Day 5: Agra Sightseeing",
                    },
                    {
                        id: 6,
                        tourCover: require('../../Assets/Categories/WildLife/Layer-6.png'),
                        alt: "Nature Safari India Tour package",
                        detail1: "Post breakfast, our Golden Triangle India Wildlife Safari tour drives to Ranthambore. Get ready to be spellbound as we take you to Abhaneri, countryside of Rajasthan’s Dausa district. The Golden Triangle with Ranthambore tour promises to showcase India’s architectural master designs. On day 6, you’ll be awed by the spectacular Chand Baori step-wells, a testament to the country’s rich heritage. You have ample time to explore the village and soak up its vibrant culture. Drive to Ranthambhore. Arrival in Ranthambore and transfer you to your luxury hotel.",
                        arrival: "Day 6: Agra - Ranthambore",
                    },
                    {
                        id: 7,
                        tourCover: require('../../Assets/Categories/WildLife/Layer-7.png'),
                        alt: "Tiger Wildlife India Package",
                        detail1: "One of the highlights of our Exotic India with Wildlife! Experience two planned Tiger safaris! Enjoy the diverse wildlife of Ranthambore, Known for its picturesque landscapes, rich flora and fauna, and the majestic Royal Bengal Tigers. Enjoy the morning Tiger Safari in Ranthambore Tiger Park. Ranthambore National Park, the magnificent creatures like the Bengal tiger are most commonly spotted in the morning. After the morning safari transfer to the hotel, post breakfast; day at leisure! In the afternoon enjoy another safari, nature lovers get ready to experience the diverse range of wildlife of Ranthambore, which includes leopards, hyenas, sloth bears, deer, and over 300 species of birds. In the evening stay in Ranthambore. .",
                        arrival: "Day 7: Ranthambore",
                    },
                    {
                        id: 8,
                        tourCover: require('../../Assets/Categories/WildLife/Layer-8.png'),
                        alt: "India Safari Tour",
                        detail1: "Post breakfast, we will drive you to ‘Paris of India’ Jaipur. In the evening, have the pleasure of visiting the magnificent Birla Temple, the preferred destination of Jaipur travel package for many tourist couples. This architectural masterpiece was constructed using modern technology while still loosely following the Nagara style, making it an awe-inspiring place for congregational worship. Transfer you to the hotel.",
                        arrival: "Day 8: Ranthambore-Jaipur",
                    },
                    {
                        id: 9,
                        tourCover: require('../../Assets/Categories/WildLife/Layer-9.png'),
                        alt: "Golden Triangle Luxury Tours",
                        detail1: "On this morning of Golden triangle India tour package; proceed to the majestic, beautiful fortress of Hawa Mahal, a stunning five-story palace made of red and pink sandstone with intricate latticework. Visit and explore the evergreen fortress of Amer, a perfect blend of Rajput and Mughal architectural styles. Last but not least, head for Jal Mahal, a serene palace in the middle of Man Sagar Lake, a floating palace on the lake’s calm waters, making it a perfect spot to give you the feel of Luxury India with a quiet reflection and relaxation. Transfer you to a hotel.",
                        arrival: "Day 9:  Jaipur Sightseeing",
                    },
                    {
                        id: 10,
                        tourCover: require('../../Assets/Categories/WildLife/Layer-10.png'),
                        alt: "golden triangle india tour",
                        detail1: "After breakfast, on day 10 of the Luxury Golden Triangle India Tour, get ready to bowl over the beauty of another world heritage and the largest astronomical observatory in the world, Jantar Mantar. Next, heading to the stunning City palace, a complex that reflects the grandeur of the Rajput era, the tour will end at Galta Ji Temple, also known as Monkey temple, a serene location perfect for meditation and relaxation. After Jaipur City Tour drive to the hotel.",
                        arrival: "Day 10: Jaipur Sightseeing",
                    },
                    {
                        id: 11,
                        tourCover: require('../../Assets/Categories/WildLife/Layer-11.png'),
                        alt: "luxury India tours",
                        detail1: "After enjoying a hearty breakfast, we will set off for Delhi where you will be transferred to your next destination or airport.",
                        detail2: "Our travel company offers different types of India Wildlife tours and tailor-made luxury India tours individually and authentically for you. Please write us or call us for Golden triangle India tour package price.",
                        arrival: "Day 11: Jaipur-Delhi",
                    },
                ]
            },
        ]
    },
    {
        id: 2,
        category: "LUXURY SOUTH INDIA",
        title: "EXPLORE, EXPERIENCE AND EXPEDITION",
        desc: "The tropical South India stretched from the enchanting Temple State Tamilnadu to soothing and the idyllic backwaters of Kerala. Explore the Dravidian style, build wonderful temples, British built & designed colonial cities like Chennai, Pondicherry along with a luxury houseboat tour on the backwater in Kerala – God’s own country. Experience the delicious cuisines and Tea plantation in Munnar, rejuvenate yourself with Kerala Ayurveda, Yoga and meditation and relax at peaceful beautiful beaches in Marari of Kerala. South India luxury Tour will impress you with grandeur architecture, incredible sculpture and vibrant colourful culture. Plan your trails of luxury south India Tour or luxury Kerala private tour with us. Lewis and Clark tours designs your India trip with private escort and professional driven car with luxury accommodation at best for your unforgettable experience. ",
        subCategory: [
            {
                id: 1,
                titleTag: "Luxury Kerala Tour, 5 Star Kerala Tour Packages, Premium Kerala Tour Packages",
                descriptions:"Book Taj Kerala packages and enjoy private luxury tours of Kerala exclusively.  Explore Kerala Luxury Tour and experience soul soothing backwater. Plan your luxury holiday packages in Kerala with Lewis and Clark Tours.",
                cover: require('../../Assets/Categories/LUXURY-KERALA-WITH-HOUSEBOAT.jpeg'),
                alt: "luxury kerala tour with houseboat",
                title: "LUXURY KERALA WITH HOUSEBOAT",
                days: "12 NIGHTS/ 13 DAYS",
                location: "Cochin - Munnar - Thekkady - Kumarakom - Alleppey - Marari - Cochin",
                desc: "Interested to explore exotic India? Plan your Taj Kerala with us. Overnight at luxury Taj Houseboat. Kerala is quiet, tropical and characterized by beautiful beaches. The backwaters of Kerala won’t let you escape from the magic of this palm-lined wonderland, crossed by canals and lagoons. Breathtakingly beautiful is the lagoon landscape of the backwaters and the the Periyar park. With its nature and exciting cultural presence, Kerala will never disappoint you.",
                className: "category-body2",
                contactForm: <ContactForm/>,
                downTitle: "Highlight of Luxury Kerala",
                downdetail1: "Overnight in Luxury Houseboat of Taj Hotels Group or at Oberoi backwater cruise.",
                downdetail2: "Our company offers different types of luxury tours of Kerala, Tailor-made like Kerala couple tours, Kerala tour package for the family at best, South India tour packages for 10 days as well Luxury Golden Triangle India with Kerala Tour package.",
                pathName: "luxury-kerala-tour",
                banner: require('../../Assets/Categories/houseboat.jpg'),
                bannerTag: "Luxury South India Tour",
                overview: require('../../Assets/Categories/BoatHouse/kerala-tour-1024x1024-overview.jpg'),
                overviewTag: "Luxury Kerala Tour Packages",
                overviewDetail: [
                    {
                        id: 1,
                        detail: "The Kerala holiday package covers some of the most popular tourist destinations of Kerala India. The journey begins in Kochi, a coastal city with a rich history of trade and colonial influence. From Kochi, the Kerala tour heads to Munnar, a hill station famous for its tea plantations and picturesque landscapes. The journey continues to ThekkadyPeriyar, a wildlife sanctuary renowned for its elephant herds and tiger sightings. Next, the Kerala trip moves to Kumarakom, a serene village on the banks of Vembanad Lake, known for its backwaters and bird sanctuaries.",
                    },
                    {
                        id: 2,
                        detail: " Finally, the luxury Kerala tour ends in Marari. You can experience the unique charm of Kerala’s backwaters by taking a houseboat ride through the intricate network of canals, lagoons, and lakes.",
                    },
                    {
                        id: 3,
                        detail: "Apart from the breathtaking beauty of these serene places, our tailor-made Kerala tour also offers you therapeutic and rejuvenating Ayurveda packages.",
                    },
                ],
                tour: [
                    {
                        id: 1,
                        tourCover: require('../../Assets/Categories/BoatHouse/Layer-1.png'),
                        alt: "Luxury India Vacations",
                        detail1: "Your India trip for Kerala Holiday Tour Packages will commence in Cochin. Your South India tour package operator will receive you. Transfer to a hotel for your luxury stay.",
                        arrival: "Day 1: Arrival in Cochin",
                    },
                    {
                        id: 2,
                        tourCover: require('../../Assets/Categories/BoatHouse/Layer-2.png'),
                        alt: "South India Tour Packages",
                        detail1: "Cochin, popularly known as the ‘Gateway of Kerala,’ is the perfect place to begin your 10 nights 11 days Kerala tour package. Cochin is popularly called the commercial capital of Kerala and, in ancient times, was considered a critical spice trading port for Arab, Chinese, Greek, and Roman traders. It is also home to famous, vibrant, ancient churches, palaces, and Synagogues. First visit Jew street, as it offers a captivating glimpse into the region’s rich Jewish history and culture and is famous for its Jewish synagogues. Followed by a visit to St. Francis church, a historic gem showcasing the Portuguese influence on the region, as it is the first European church built in India in the 16th century. Later, arriving at Dutch Palace in Mattancherry offers a glimpse into the region’s colonial past. Explore the stunning architecture and impressive collection of murals and artifacts. Then visit Kochi Fort, popularly known as Cheenvala, the Chinese fishing nets are fascinating places to visit. In the Evening, drive you to a cultural center to give you glimpses of rich Kerala culture. Transfer you to the luxury hotel.",
                        arrival: "Day 2: Cochin Sightseeing",
                    },
                    {
                        id: 3,
                        tourCover: require('../../Assets/Categories/BoatHouse/Layer-3.png'),
                        alt: "India vacation Packages all inclusive",
                        detail1: "After breakfast, en route, visit the Cheeyappara & Valara waterfalls, where you can enjoy the serene view of Valara falling from a distance. It is one of the popular trekking locations in the Idukki district. Head to Munnar and check in to the luxury resort. Munnar is nestled in the hills of Kerala, a paradise for nature lovers. Experience the awe-inspiring sightseeing of sprawling tea plantations, cascading waterfalls, and misty mountains, Munnar offers a serene escape from the hustle-bustle of city life. Transfer you to the hotel.",
                        arrival: "Day 3: Cochin – Munnar ",
                    },
                    {
                        id: 4,
                        tourCover: require('../../Assets/Categories/BoatHouse/Layer-4.png'),
                        alt: "Exotic India Tour",
                        detail1: "On day 4 of the Taj Munnar package, get ready to experience exotic Kerala with a visit to Rajamala. Experience an unforgettable Kerala adventure with the journey to Kerala’s Eravikulam National Park, home to endangered species like the Nilgiri Tahr. Trek through lush green forests, spot rare wildlife, and marvel at the stunning mountain landscapes. Next, heading to the gorgeous Mattupetty Dam, located between mountains. Echo point, the final but essential destination on your day 4 Kerala trip package, is a highly visited location where sound waves bounce off the distant hills to produce an intriguing echo effect. Overnight stay at the luxury hotel!",
                        arrival: "Day 4: Munnar Sightseeing",
                    },
                    {
                        id: 5,
                        tourCover: require('../../Assets/Categories/BoatHouse/Munnar-Sightseen-5.jpg'),
                        alt: "Luxury Tour Operators in India",
                        detail1: "After breakfast, check out the hotel, Kerala trip continues for another mesmerizing journey to Thekkady. Located in the Idukki district of Kerala, it is a perfect blend of adventure and tranquility, making it an ideal destination for a refreshing getaway. Get ready to be dazzled by the beauty of Periyar National Park and Tiger Reserves, renowned as the most beautiful wildlife park on earth. With its picturesque landscapes, moist deciduous type vegetation, sprawling spice plantations, and diverse wildlife, Thekkady offers an unparalleled experience. Overnight stay at the hotel.",
                        arrival: "Day 5: Munnar - Thekkady",
                    },
                    {
                        id: 6,
                        tourCover: require('../../Assets/Categories/BoatHouse/Layer-6.png'),
                        alt: "Luxury Tours in India",
                        detail1: "The following day of the South India tour package allows you to freely engage in various activities and spend the day at your leisure! Periyar Lake boating, spice plantation tours, Elephant safaris, agricultural farm tours, sightseeing, nature hikes, adventure camping, border walks, bamboo rafting, jungle jeep safaris, Kalaripayattu (a traditional martial art) performances, and tribal art displays are just some of the activities that can be enjoyed. Spend the night in Thekkady.",
                        arrival: "Day 6: Thekkady Sightseeing",
                    },
                    {
                        id: 7,
                        tourCover: require('../../Assets/Categories/BoatHouse/Layer-7.png'),
                        alt: "India Private Holiday Package",
                        detail1: "Post Breakfast, drive you to Kumarakom. In January 2023, New York Times chose Kumarakom among the must-visit 52 places worldwide, where Kumarakom got special mention for its famous backwater tourism. You can collect lifetime memories by visiting these exotic places as the full day is at your leisure. Transfer you to the hotel for an overnight stay.",
                        arrival: "Day 7: Thekkady-Kumarakom",
                    },
                    {
                        id: 8,
                        tourCover: require('../../Assets/Categories/BoatHouse/Layer-8.png'),
                        alt: "Private Tours India",
                        detail1: "On this day of South India tour packages, you are at your leisure to enjoy the fascinating backwater paradise of Kumarakom. You can visit a neighboring bird sanctuary as Kumarakom is considered an ‘ornithologist paradise’ and home of majestic bird species like Heron, teal, darter, Siberian stork, etc. Transfer to the hotel.",
                        arrival: "Day 8:  Kumarakom Sightseeing",
                    },
                    {
                        id: 9,
                        tourCover: require('../../Assets/Categories/BoatHouse/Layer-9.png'),
                        alt: "Kerala holiday package",
                        detail1: "You can engage in your recreation activities as morning is at your leisure! Check out the resort and drive to another destination– Allepey of Taj hotels Kerala holiday package. After arriving at Alleppey, board the houseboat at noon for the Oberoi backwater cruise. Get ready to be spellbound with an incredible serene view of islands and communities with coconut plantations, paddy fields, Chinese fishing nets, and toddy tappers at work. Overnight stay at the boathouse.",
                        arrival: "Day 9: Kumarakom-Alleppey",
                    },
                    {
                        id: 10,
                        tourCover: require('../../Assets/Categories/BoatHouse/Layer-10.png'),
                        alt: "luxury tour of Kerala",
                        detail1: "After breakfast, drive you to Marai, another ideal and Kerala’s exotic beach destination for a private luxury tour of Kerala. A beautiful beach on the coast of the Arabian Sea is perfect for holidaying with your family and friends. The entire day is at your leisure! You can visit the beaches of Marari with coconut palm trees and golden sands. Transfer you to the hotel.",
                        arrival: "Day 10: Alleppey-Marari",
                    },
                    {
                        id: 11,
                        tourCover: require('../../Assets/Categories/BoatHouse/beach-marari-11.jpg'),
                        alt: "best travel agency in india",
                        detail1: "You can indulge in your recreation activities on this day with the family. You can experience the serenity and tranquility of the beautiful beaches of Marari, taking you backward in time. You can also explore Yoga und traditional Ayurveda treatment. You can also visit the neighbouring Kumarakom Bird sanctuary, where you can see rare birds like the Indian darter, Little Cormorant, Herons, and White Ibis. Overnight stay at the hotel.",
                        arrival: "Day 11: Marari",
                    },
                    {
                        id: 12,
                        tourCover: require('../../Assets/Categories/BoatHouse/the-paradise-of-east-12.jpg'),
                        alt: "luxury india tours",
                        detail1: "Post breakfast, the full day is at your leisure! You can visit the nearby tranquil beaches, and adventure freaks can indulge in sea-surfing, parasailing, water skiing, and deep-sea fishing. You can also enjoy yoga classes and Ayurveda treatment along the beach and resorts. Overnight stay at the hotel.",
                        arrival: "Day 12: Marari",
                    },
                    {
                        id: 13,
                        tourCover: require('../../Assets/Categories/BoatHouse/Marari-Cochin-13.jpg'),
                        alt: "Luxury Holidays Vacations in India",
                        detail1: "After enjoying the hearty breakfast, check out from the hotel and set off for the Cochin. Board the flight to your onward destination.",
                        arrival: "Day 13: Marari-Cochin",
                    },
                ]
            },
            {
                id: 2,
                titleTag: "South India Luxury Tours, Private Tour South India, Luxury South India Tour Packages",
                descriptions:"Discover the the exotics charms in Southern India and experience South India tours from USA with best luxury tour company India. Our South India luxury trave offers a journey through ancients temples, luxury Hotels, tropical backwaters and with Tiger Park.",
                cover: require('../../Assets/Categories/LUXURY-SOUTH-INDIA-TOUR.jpg'),
                alt: "luxury South India tour package",
                title: "TRAILS OF LUXURY SOUTH INDIA TOUR",
                days: "16 NIGHTS/ 17 DAYS",
                location: "Chennai - Mahabalipuram - Thanjavur - Madurai - Munnar - Thekkady - Kumarakom - Cochin",
                desc: "India – the land of many faces. North India shows Taj Mahal where South India introduces peaceful Kerala with rejuvenating Ayurveda. This India private tour packages bring you the highlight of Golden triangle tour in north and mixture of south India tour Packages with luxurious holidays. Learn the colonial India Delhi, the local Dravidian Culture and explore state of temple and many more in this Luxury vacation India.",
                className: "category-body1",
                contactForm: <ContactForm/>,
                pathName: "trails-of-luxury-south-india-tour",
                downdetail1:"We offer tailor-made Taj Bekal holiday package,Taj Kovalam package, Oberoi Golden Triangle Tour Delhi Agra Jaipur and Luxury Maharaja Tour Rajasthan in North India. Please write us or call us for an unforgettable luxury tour. We do also plan for Taj Luxury Wildlife Tour India.",
                banner: require('../../Assets/Categories/mmararibeach-home-1.jpg'),
                bannerTag: "luxury south india tour",
                overview: require('../../Assets/Categories/SouthIndia/Luxury-India-through-the-land-overview.jpeg'),
                overviewTag: "Luxury India Tour with Taj Hotels",
                overviewDetail: [
                    {
                        id: 1,
                        detail: "Exotic South India offers a cultural and scenic journey through some of South India’s most fascinating destinations. Starting in Chennai, you’ll make your way to the UNESCO-listed Mahabalipuram, then head south to the French-influenced city of Pondicherry. After exploring the historic temples of Thanjavur and the Chettinad region’s traditional mansions in Karaikudi, you’ll continue south to the vibrant city of Madurai. Drive to the Western Ghats to experience the breathtaking tea plantations of Munnar and the wildlife-rich forests of Thekkady. Finally, your 14 day South India trip ends with a relaxing houseboat cruise through the serene backwaters of Kumarakom before concluding in the bustling port city of Cochin.",
                    },
                ],
                tour: [
                    {
                        id: 1,
                        tourCover: require('../../Assets/Categories/SouthIndia/Mahabalipuram-1.jpg'),
                        alt: "Luxury South India Tour",
                        detail1: "Your India trip for a luxury South India tour package will commence in Chennai. Your South India Tour operator receives you. Transfer to a hotel for your luxury stay.",
                        detail2: "Chennai is a bustling metropolis with a rich history, formerly known as Madras is the perfect destination to begin your luxury south India tour packages. Chennai’s strategic coastal location has always made it a hub of trade in silks, spices, and jewels. The march of progress demanded the rise of industries, and the textile sector was among the first to answer the call. Their pioneering efforts set the stage for a new era of growth and prosperity. Today, Chennai’s streets are a beautiful blend of old and new, where towering skyscrapers stand alongside historical buildings that now serve as modern-day administrative centers. Despite these changes, Chennai’s culture remains its defining feature, with classical dance from Bharatanatyam and Carnatic music celebrated during the month-long festival every December. Experience the city’s diverse cuisine dominated by rice-based preparations in Chennai Tour.",
                        arrival: "Day 1 - 2: Chennai",
                    },
                    {
                        id: 2,
                        tourCover: require('../../Assets/Categories/SouthIndia/Pondicherry-2.jpg'),
                        alt: "India Luxury Tour Packages",
                        detail1: "Take a picturesque drive down the East Coast Road towards Mahabalipuram, a charming coastal town known for its steeped in culture and history. It is renowned for several historic monuments and buildings that are considered architectural marvels. Mahabalipuram is a treasure trove of architectural wonders, with monolithic chariots, rock-cut caverns, shore temples, shrines, and a balanced boulder from the last Ice Age glaciers among them. It bears witness to the development of sculpture styles and icons, the transition of dynasties and religions, and the shifting influences and regimes.",
                        arrival: "Day 3: Mahabalipuram",
                    },
                    {
                        id: 3,
                        tourCover: require('../../Assets/Categories/SouthIndia/Pondicherry-3.jpg'),
                        alt: "Luxury Kerala Tour Packages",
                        detail1: "After breakfast, continue your journey to the ‘City of Dawn’ Pondicherry. Pondicherry stands out for preserving the essence of its European colonial past, which can be experienced on every street and around every corner. Taj Munnar Packages is enough to give you the rich experience of its Creole cuisine, and canary-yellow architecture, with hybrid road signs that fuse Indian and French influences. Pondicherry retains its Gallic flavor, while its people’s cultural identity reflects a blend of Tamil heritage and colonial assimilation. Aurobindo Ashram adds a unique flavor to Pondicherry. This Ashram, established by AurobindoGhosh, a freedom fighter and philosopher-poet, is founded on holistic lifestyle principles. The community-centered and less materialistic way of life has attracted people from all over India. Auroville, situated a few kilometers from Pondicherry, is a Utopian city based on similar ideals that have become a sanctuary for like-minded people worldwide.",
                        arrival: "Day 4 - 5: Pondicherry",
                    },
                    {
                        id: 4,
                        tourCover: require('../../Assets/Categories/SouthIndia/Thanjavur-4.jpg'),
                        alt: "Private Luxury India Tours",
                        detail1: "This morning, steer for Thanjavur, another mesmerizing destination for our Luxury South India Tamil Nadu Tour. Moving along, visit the Chidambaram Nataraja Temple, tge Hindu temple dedicated to Nataraja, the form of Shiva as the lord of the dance. Admire the beautiful paintings, towers, and intricate carvings decorated on this old temple. Heading to the Thanjavur, located in the Cauvery delta and often called the ‘Rice bowl of Tamil Nadu’ and famous as the center of religion, art, and architecture in South India. Venture to the heart of Thanjavur and experience the beauty and grandeur of the Great Living Chola Temples, esteemed UNESCO World Heritage Monuments.",
                        arrival: "Day 6 - 7 Pondicherry",
                    },
                    {
                        id: 5,
                        tourCover: require('../../Assets/Categories/SouthIndia/Madurai-5.jpg'),
                        alt: "Luxury South India Tour",
                        detail1: "Post breakfast,make your way towards Karaikudi. While advancing, visit the stunning ancient Namanasamudram temple located in the foothills of the Eastern Ghats, renowned for its unique architectural style and serene surroundings. The temple’s main deity is Lord Shiva, and it is believed that the lingam here was self-manifested. It is surrounded by hills, lush greenery, and a picturesque waterfall, making it an ideal place for meditation and spiritual retreats. Advance to Karaikudi, known for its rich culture and heritage. Indulge in the stunning Chettinad architecture, relish the authentic Chettinad cuisine, and shop for exquisite handmade crafts.",
                        arrival: "Day 8: Thanjavur",
                    },
                    {
                        id: 6,
                        tourCover: require('../../Assets/Categories/SouthIndia/Munnar-6.jpg'),
                        alt: "Luxury Tour Operators in India",
                        detail1: "Madurai is a city widely known as ‘ThoongaNagaram’ – a city that never sleeps. Set out for Madurai on day 9 of the Taj Kerala package. Madurai has a history of over 2,550 years and is considered the oldest city in Tamil Nadu, prominently known as the ‘Athens of the East.’The city of Madurai, which functioned as the Pandya Kings’ capital during their rule, is peppered with a rich cultural heritage and pre-Christian past that is documented.",
                        arrival: "Day 9-10: Madurai",
                    },
                    {
                        id: 7,
                        tourCover: require('../../Assets/Categories/SouthIndia/Thekkady-7.jpg'),
                        alt: "luxury tour of Kerala",
                        detail1: "After breakfast, drive you to the Munnar, popularly known as the ‘Kashmir of South India’ of the Taj Munnar Package. With many beautiful hill stations, Munnar offers awe-inspiring trails and hikes through its lush green garden sprawling over a vast area. Munnar is blessed with mesmerizing landscapes of nature, cloud-kissing national parks, serene rivers and lakes, and a storehouse for rare and endangered wildlife. Munnar is located at the confluence of three mountain streams– Muthirapuzha, Kundala, and Nallathanni– and the word ‘Munnar’ is known as three rivers in Malayalam.",
                        arrival: "Day 11: Munnar",
                    },
                    {
                        id: 8,
                        tourCover: require('../../Assets/Categories/SouthIndia/Kumarakom-8.jpg'),
                        alt: "Luxury India Vacations",
                        detail1: "The luxury tour of Kerala showcases the rich Flora and fauna of Thekkady. Enjoy the natural biodiversity of Periyar National Park, home to some majestic species of birds, wildlife, and aquatic animals. You can also experience lofty creatures like tigers in the Periyar National Park. PeriyarRiver is celebrated for its water birds and is the home of some rare, endemic, and endangered species in a protected area.",
                        arrival: "Day 12: Thekkady",
                    },
                    {
                        id: 9,
                        tourCover: require('../../Assets/Categories/SouthIndia/Cochin-9.jpg'),
                        alt: "Exotic India Holidays",
                        detail1: "Post breakfast, head towards the Kumarakom, popularly known as the gateway to the backwaters. Enjoy the lush green vegetation, which helps nurture both avian and aquatic wildlife. Those nature lovers keen to spot the kingfisher and other resplendent birds should include the Kumarakom Bird Sanctuary on their agenda. While selecting the facilities, it can be a thoughtful gesture to have authentic Ayurveda massages in your indulgence list of Kerala Ayurveda packages.",
                        arrival: "Day 13-14: Kumarakom",
                    },
                    {
                        id: 10,
                        tourCover: require('../../Assets/Categories/SouthIndia/Cochin-10.jpg'),
                        alt: "Best of India’s Luxury Packages",
                        detail1: "Cochin is the perfect place to end your luxury tours of Kerala. Cochin is a vibrant city, located on the Southwestern coast of India, an ideal blend of history, culture, and natural beauty that gives you a unique and unforgettable travel experience. It is a city that captures your heart and leaves you with memories that will last a lifetime. The city has a fascinating mix of influences from different cultures, including Portuguese, Dutch, and British, reflected in its architecture and cuisine. You can explore the ancient Chinese fishing nets that still operate in the harbor, visit the historic Fort Kochi, and admire the beautiful murals and frescoes in the local churches.",
                        detail2: "After enjoying the hearty breakfast and an exotic experience in Kerala South India Luxury Tour, check out from the hotel and set off for the Cochin. Board the flight to your onward destination.",
                        arrival: "Day 15-16: Cochin",
                    },
                ]
            },
        ]
    }
]

export default CategoryData;